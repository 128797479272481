import React from "react"
import Layout from "../components/common/layout"
import { graphql } from "gatsby"
import MurrinaPage from "../components/slugPages/murrinaPage"

const MurrinaPageTemplate = ({ data, location }) => (
  <Layout
    pageMeta={{
      title: `${data.strapiMurrine.nome}`,
      keywords: [`${data.strapiMurrine.nome} ${data.strapiMurrine.marchio.nome}`],
      description: `${data.strapiMurrine.nome}`,
      link: `/${data.strapiMurrine.categoria}/${data.strapiMurrine.slug}`,
    }}
    location={location}
  >
    <MurrinaPage post={data.strapiMurrine} location={location} />
  </Layout>
)
export default MurrinaPageTemplate

export const postQuery = graphql`
  query MurrinaPageQuery($slug: String!) {
    strapiMurrine(slug: { eq: $slug }) {
      strapiId
      slug
      nome
      prezzo
      categoria
      descrizione
      promo {
        codice
        inizio
        fine
      }
      marchio {
        nome
        logo {
          alternativeText
          hash
          width
          height
          url
        }
      }
      variante_colore {
        colore
        quantita
        foto {
          alternativeText
          hash
          width
          height
          url
        }
      }
    }
  }
`