import React, {useState, useRef, useEffect, useContext} from "react"
import AddToCartIcon from "../../assets/cart.svg"
import Markdown from "react-markdown"
import { StoreContext } from "../../context/storeContext"
import markdownStyles from "../../styles/common/markdown.module.scss"
import murrinaPageStyles from "../../styles/slugPages/murrina-page.module.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {isDateEarlier, formatDate} from "../../utilities/dateUtils"

const gfm = require('remark-gfm')

const MurrinaPage = ({ post, location }) => {
  const { addVarianteToCommittedCart } = useContext(StoreContext)

  const [color, setColor] = useState(post.variante_colore[0].colore)
  const [quantities, setQuantities] = useState([])

  const today = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' })

  const [state, setState] = useState({ nav1: null, nav2: null });
  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current
    });
    getQuantities(post).then(qtyData => {
      setQuantities(qtyData)
    })
  }, []);

  const { nav1, nav2 } = state;


  const settings = {
    arrows: false,
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    swipeToSlide: false,
    fade: true,
    adaptiveHeight: true
  }

  const slidesNum = post.variante_colore.length
  
  function LinkRenderer(props) {
    return <a href={props.href} target="_blank">{props.children}</a>
  }

  const getVarianteQuantity = async (id, variante) => {
        try {
          const res = await fetch(`${process.env.GATSBY_STRAPI_PATH}/murrines/${id}`, {keepalive: true, method: "GET",})
          if (!res.ok) {
            throw("fail")
          }
          const murrina_db = await res.json()
          const varianti = murrina_db.variante_colore
          const quantita = varianti.find(obj => obj.colore === variante.colore).quantita;
          return quantita}
          catch(err) {
            //console.log(err)
          }
  }

  const getQuantities = async (post) => {
    try{
    const qtyList = Promise.all(post.variante_colore.map( variante => getVarianteQuantity(post.strapiId, variante)))
    return qtyList} catch(err) {
      //console.log(err)
    }
  }

  return (
      <div className={murrinaPageStyles.murrina}>
        <Slider {...settings}  asNavFor={nav2} ref={slider => (slider1.current = slider)} className={murrinaPageStyles.imageBox} >
                  {post.variante_colore.map((variante,i) => (                    
                    <div key={`${post.strapiId}-${i}`}>
                      <p className={murrinaPageStyles.imageSliderText} >colore: {post.promo && post.categoria === 'lampade' ? `come da descrizione e foto`: variante.colore.trim().replace(/_/g, ' ')}</p>
                      <p className={murrinaPageStyles.imageSliderText2} >disponibili in negozio: {quantities[i]}</p>
                    {(variante.foto[0].height >= variante.foto[0].width) ?
                        <img className={murrinaPageStyles.image} src={`${process.env.GATSBY_CLOUDINARY_URL_SMALL}${variante.foto[0].hash}`} loading="lazy"/> : <img className={murrinaPageStyles.image} src={`${process.env.GATSBY_CLOUDINARY_URL_SMALL_ROTATE}${variante.foto[0].hash}`} loading="lazy"/>}
                        {(variante.foto.length !== 1) && variante.foto.map((foto, j) => (j > 0) && ((foto.height >= foto.width) ?
                        <img key={j} className={murrinaPageStyles.image} src={foto.url} loading="lazy"/> : <img key={j} className={murrinaPageStyles.image} src={`${process.env.GATSBY_CLOUDINARY_URL}${foto.hash}`} loading="lazy"/>))}
                    </div> ))
                  }
        </Slider>
 
        {post.nome.includes("stilografica") ? <span style={{marginLeft: "1rem", fontSize: "1rem", color: "white", fontWeight: "bold"}}>KALIGRAFICA</span> : <img className={murrinaPageStyles.logo} src={post.marchio.logo[0].url}/>}
        <div className={murrinaPageStyles.titleWrapper}>
          <h2 className={murrinaPageStyles.title}>{post.nome} </h2>
          {post.promo && post.promo.codice !== 'NOPROMO' &&
          isDateEarlier(formatDate(post.promo.inizio), today) && isDateEarlier(today, formatDate(post.promo.fine)) &&
          <p className={murrinaPageStyles.promo}>PRODOTTO IN PROMOZIONE: al checkout applica il codice {post.promo.codice}</p>}
        </div>
        <div className={murrinaPageStyles.content}>
          <Markdown className={markdownStyles.testo} plugins={[gfm]} renderers={{link: LinkRenderer}}>{post.descrizione}</Markdown>
          {slidesNum > 1 && <h4 className={murrinaPageStyles.h4}>seleziona il colore</h4>}
          {slidesNum > 1 && <Slider
            className={slidesNum === 2 ? murrinaPageStyles.width40 : 
              ((slidesNum ===3) ? murrinaPageStyles.width60 : 
              ((slidesNum ===4) ? murrinaPageStyles.width80 : murrinaPageStyles.width100))}
            asNavFor={nav1}
            ref={slider => (slider2.current = slider)}
                  slidesToShow={post.variante_colore.length}
                  swipeToSlide={true}
                  focusOnSelect={true}
          > 
          {post.variante_colore.map((variante,i) => (                    
            <div key={`${post.strapiId}-${i}-v`}>
            {(variante.foto[0].height >= variante.foto[0].width) ?         
              <img onClick={() => setColor(variante.colore)} className={murrinaPageStyles.imageSlider} src={`${process.env.GATSBY_CLOUDINARY_URL_SMALL}${variante.foto[0].hash}`} loading="lazy"/> : <img onClick={() => setColor(variante.colore)} className={murrinaPageStyles.imageSlider} src={`${process.env.GATSBY_CLOUDINARY_URL_SMALL_ROTATE}${variante.foto[0].hash}`} loading="lazy"/>}
            </div>))
          }         
          </Slider>}
        </div>

       {quantities[0] === 0 && (post.promo && post.promo.codice !== 'NOPROMO') || 
       (quantities[0] === 0 && post.categoria === 'oggettistica' && post.marchio.nome==='La Murrina' && post.slug !== 'specchio-burano') ? 
        <p className={murrinaPageStyles.buyBtn}>NON DISPONIBILE IN NEGOZIO, DA ORDINARE</p> :
         <button className={murrinaPageStyles.buyBtn} onClick={() => addVarianteToCommittedCart(post, color)}>
          <span>{post.prezzo}€ <AddToCartIcon className={murrinaPageStyles.iconCart}/></span>
          <p className={murrinaPageStyles.btnText}>aggiungi al carrello</p>
          </button> }
          <p className={murrinaPageStyles.leadtime}>NOTA SUI TEMPI DI CONSEGNA: il carattere artigianale e l'unicità dei nostri prodotti potrebbero comportare tempi di lavorazione più lunghi dei comuni ecommerce. 
        I pezzi disponibili in negozio verranno consegnati entro 15 giorni lavorativi dal pagamento.
        I pezzi non disponibili in negozio verranno spediti entro 60 giorni dal pagamento.
        
       {post.categoria === 'lampade' && <><br /><br />Le lampadine non sono incluse.</>} </p>
      </div>

  )
}

export default MurrinaPage